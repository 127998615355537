import React from 'react';
import "./global.scss";

function App() {
  const [target, setTarget] = React.useState(0);
  const [current, setCurrent] = React.useState(0);
  const [pages, setPages] = React.useState(0);

  React.useEffect(() => {
    let target = localStorage.getItem('target');
    let current = localStorage.getItem('current');

    if (target) {
      setTarget(target);
    }

    if (current) {
      setCurrent(current);
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem('target', target);
    localStorage.setItem('current', current);

    let newPages = (target - current) / 2400;
    setPages(newPages.toFixed(2));
  }, [target, current]);

  return (
    <div className="App">
      <div className="wrapper">
        <h1>Page counter</h1>
        <input type="number" placeholder="Target characters" 
          onChange={(e) => setTarget(e.target.value)}
          value={target}
        />
        <input type="number" placeholder="Current characters" 
          onChange={(e) => setCurrent(e.target.value)}
          value={current}
        />
        <h3>Pages left: {pages}</h3>
      </div>
    </div>
  );
}

export default App;
